const config = {
  apiKey: "AIzaSyBe7LJkadG-YKej1YMOnlQ9qR4vyEoVYFU",
  authDomain: "greatlist.io",
  databaseURL: "https://greatlist-46a4c.firebaseio.com",
  projectId: "greatlist-46a4c",
  storageBucket: "gs://greatlist-46a4c.appspot.com",
  messagingSenderId: "523329685349",
  appId: "1:523329685349:web:f556fe59e56dabf7c4c38c",
};

export default config;
