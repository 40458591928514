import React, { useState } from "react";
import firebase from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { Alert } from "shards-react";
import App from "./App";
import Login from "./Login";
import Signup from "./Signup";
import Loader from "./Loader";
import Forget from "./Forget";
import About from "./About";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "react-dates/lib/css/_datepicker.css";

const AuthApp = () => {
  const [user, loading, error] = useAuthState(firebase.auth());

  const logout = () => {
    firebase.auth().signOut();
  };

  const [page, setPage] = useState("Login");

  return (
    <div>
      {(page === "Login" || page === "Signup") && !user && !loading ? (
        <div style={{ marginTop: 20, marginRight: 50, float: "right" }}>
          <a
            className="navlink"
            onClick={(e) => {
              e.stopPropagation();
              setPage("About");
            }}
          >
            About
          </a>
          <a
            className="navlink"
            style={{ marginLeft: 20 }}
            target="_blank"
            rel="noopener noreferrer"
            href="mailto: admin@greatlist.io"
          >
            Contact
          </a>
        </div>
      ) : (page === "About" || page === "Forget") && !user && !loading ? (
        <div style={{ marginTop: 20, marginRight: 50, float: "right" }}>
          <a className="navlink" onClick={() => setPage("Signup")}>
            Sign up
          </a>
          <a
            className="navlink"
            style={{ marginLeft: 20 }}
            target="_blank"
            rel="noopener noreferrer"
            href="mailto: admin@greatlist.io"
          >
            Contact
          </a>
        </div>
      ) : null}
      <div className="background-colors" />
      <div className="background-colors-faded" />
      <div className="background-colors-faded-2" />
      <div className="background-colors-faded-3" />
      {loading || error ? (
        <div
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: "auto",
            height: 50,
            width: 50,
          }}
        >
          <Loader />
          {error && (
            <Alert theme="danger">
              {error.message || "Sorry, something went wrong."}
            </Alert>
          )}
        </div>
      ) : user ? (
        <App user={user} logout={logout} />
      ) : page === "About" ? (
        <About setPage={setPage} />
      ) : page === "Login" ? (
        <Login setPage={setPage} />
      ) : page === "Signup" ? (
        <Signup setPage={setPage} />
      ) : page === "Forget" ? (
        <Forget setPage={setPage} />
      ) : null}
      <span
        style={{
          position: "absolute",
          right: 20,
          zIndex: -1,
          bottom: 10,
          color: user || loading ? "transparent" : "grey",
        }}
      >
        {" "}
        © GreatList 2019{" "}
      </span>
    </div>
  );
};

export default AuthApp;
