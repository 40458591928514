import React, { useState, useEffect } from "react";
import {
  getBacklogItems,
  likeTodo,
  unlikeTodo,
  addBacklogItem,
} from "./services/todo";
import {
  ListGroup,
  ListGroupItem,
  FormCheckbox,
  FormInput,
  Collapse,
  InputGroupAddon,
  InputGroup,
  Button,
  Modal,
  ModalBody,
  Dropdown,
  Alert,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Tooltip,
  Card,
  Badge,
} from "shards-react";
import Loader from "./Loader";

const Backlog = ({ user, coins }) => {
  const [text, handleText] = useState("");
  const [items, setItems] = useState([]);
  const [doneItems, setDoneItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getBacklogItems().then((querySnapshot) => {
      setIsLoading(false);
      const backlogItems = querySnapshot.docs.map((a) => ({
        ...a.data(),
        id: a.id,
      }));
      setItems(backlogItems.filter((a) => !a.done));
      setDoneItems(backlogItems.filter((a) => a.done));
    });
  }, [coins]);
  if (isLoading) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <Card style={{ marginTop: 20, marginBottom: 20 }}>
        <div style={{ margin: 20 }}>
          Welcome to our backlog.
          <br />
          <br />
          Here you can see what we are working on at GreatList, and also shape
          what we build next by liking different backlog items. You can also add
          backlog items and we will endeavour to build them for you! Adding an
          item costs 1 coin, to get a coin you have to make a £1 donation to us
          via stripe
          <button
            style={{ color: "rgb(0, 162, 255)", fontWeight: "bold" }}
            id="checkout-button-sku_FsoapjSJPWRlgW"
            role="link"
            onClick={() => {
              const stripe = window.Stripe(
                "pk_live_88Bo8zyBkQYMElnVyHj5NwX500FP0TKz27"
              );

              stripe.redirectToCheckout({
                items: [{ sku: "sku_Ftxo9LOrhFYo3i", quantity: 1 }],
                customerEmail: user.email,
                clientReferenceId: user.uid,
                successUrl: "https://greatlist.io",
                cancelUrl: "https://greatlist.io",
              });
            }}
          >
            &nbsp;here.
          </button>
          <br />
          We hope you are enjoying using GreatList and also that we can make it
          better for you.
          <br />
          Current Team Size: 1
        </div>
      </Card>
      <InputGroup style={{ marginBottom: 20, marginTop: 20 }}>
        <InputGroupAddon type="prepend">+</InputGroupAddon>
        <FormInput
          placeholder="Add a to-do..."
          value={text}
          disabled={coins < 1}
          onChange={(e) => {
            handleText(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && text.length > 0) {
              setIsLoading(true);
              addBacklogItem(user.uid, user.email, text)
                .then((a) => console.log(a))
                .catch((a) => console.log(a));
              // add todo to backlog
              handleText("");
            }
          }}
        />
      </InputGroup>
      <ListGroup>
        {items
          .sort((a, b) => a.listIndex - b.listIndex)
          .map((todo) => (
            <ListGroupItem style={{ display: "flex" }}>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "inline",
                }}
              >
                {todo.text}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row-reverse",
                  flex: "auto",
                  color: "#FF0055",
                  opacity:
                    todo.likes && todo.likes.includes(user.uid) ? 1 : 0.22,
                }}
              >
                <button
                  onClick={() => {
                    if (todo.likes) {
                      if (!todo.likes.includes(user.uid)) {
                        likeTodo(user.uid, todo.id);
                        setItems(
                          items.map((a) =>
                            a.id === todo.id
                              ? { ...a, likes: [...a.likes, user.uid] }
                              : a
                          )
                        );
                        return;
                      } else {
                        unlikeTodo(user.uid, todo.id);
                        setItems(
                          items.map((a) =>
                            a.id === todo.id
                              ? {
                                  ...a,
                                  likes: a.likes.filter((a) => a !== user.uid),
                                }
                              : a
                          )
                        );
                        return;
                      }
                    }
                    likeTodo(user.uid, todo.id);
                    setItems(
                      items.map((a) =>
                        a.id === todo.id ? { ...a, likes: [user.uid] } : a
                      )
                    );
                  }}
                >
                  <div>
                    {(todo.likes && todo.likes.length) || 0} &nbsp;
                    <i
                      style={{ color: "#FF0055" }}
                      className="fas fa-heart"
                    ></i>
                  </div>
                </button>
              </div>
            </ListGroupItem>
          ))}
        <div style={{ textAlign: "center" }}>
          <Button theme="success" style={{ margin: 20, pointerEvents: "none" }}>
            Completed
          </Button>
        </div>
        {doneItems.map((todo) => (
          <ListGroupItem style={{ display: "flex" }}>
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "inline",
                textDecoration: "line-through",
              }}
            >
              {todo.text}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                flex: "auto",
                color: "#FF0055",
                opacity: 0.2,
              }}
            >
              <div>
                {(todo.likes && todo.likes.length) || 0}{" "}
                <i className="fas fa-heart"></i>
              </div>
            </div>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

export default Backlog;
