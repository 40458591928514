import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { equals, uniq } from "ramda";
import {
  ListGroup,
  ListGroupItem,
  FormCheckbox,
  FormInput,
  Collapse,
  InputGroupAddon,
  InputGroup,
  Button,
  Modal,
  ModalBody,
  Dropdown,
  Alert,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Tooltip,
  Badge,
} from "shards-react";
import { SingleDatePicker } from "react-dates";
import TextareaAutosize from "react-autosize-textarea";
import { isMobile } from "react-device-detect";
import Linkify from "react-linkify";
import ListItemDnd from "./ListItemDnd";
import Loader from "./Loader";
import "react-dates/initialize";
import uuid from "uuidv4";
import robot from "./robot.png";
import { DndProvider } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import useLocalStorage from "./services/useLocalStorage";
import useAudio from "./lib/useAudio";
import successNoise from "./success.mp3";
import {
  updateUserEmail,
  getUserFeed,
  updateUserFeedSeen,
  getUserByEmail,
  addActivityFeedItem,
  setUserPushToken,
  fromFsTypeActivity,
  getUser,
  deleteUser,
  updateUserImage,
  subscribeUser,
  inviteUser,
} from "./services/user";
import {
  getListsByUsersEmail,
  deleteList as deleteListRemote,
  createList as createListRemote,
  updateList,
} from "./services/list";
import {
  getTodosCreatedByUser,
  getTodosByList,
  updateTodo,
  deleteTodo as deleteTodoRemote,
  fromFsType,
  backlogId,
} from "./services/todo";
import Backlog from "./Backlog";
import { isEmail } from "./lib/library";
import { askForPermissioToReceiveNotifications } from "./services/pushNotifications";

// Hook
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const App = ({ user, logout }) => {
  const [displayUser, setDisplayUser] = useState(user);
  const [userImageState, setUserImageState] = useState(true);
  useEffect(() => {
    if (userImageState) {
      setUserImageState(false);
      getUser(user.uid)
        .then((qs) => {
          const a = qs.data();
          setDisplayUser({ ...user, ...a });
        })
        .catch((e) => console.log(e));
    }
  }, [userImageState]);

  const [refresh, setRefresh] = useState(true);
  const [playing, toggle] = useAudio(successNoise);
  const [comingSoon, setComingSoon] = useState(false);
  const [todos, setTodos] = useState([]);
  const [doneTodos, setDoneTodos] = useState([]);
  const [loadingList, setLoadingList] = useState(true);
  const [activityfeedShow, setActivityFeedShow] = useState(false);

  useEffect(() => {
    updateUserEmail(user);
  }, [user]);

  const [coins, setCoins] = useState(0);

  useEffect(() => {
    subscribeUser(user.uid).onSnapshot((qs) => {
      const a = qs.data();
      setCoins(a.coins || 0);
    });
  }, []);

  const [activityfeed, setActivityfeed] = useState([]);
  useEffect(() => {
    getUserFeed(user.uid).onSnapshot((qs) => {
      const a = qs.docs.map((a) => fromFsTypeActivity(a.data()));
      setActivityfeed(a);
    });
  }, [user]);

  const setSeenActivity = (activities) =>
    Promise.all(
      activities.map((feedItem) => updateUserFeedSeen(user.uid, feedItem.id))
    );

  // get todos
  useEffect(() => {
    if (refresh) {
      getListsByUsersEmail(user.email)
        .then((querySnapshot) => {
          const lists = querySnapshot.docs.map((a) => ({
            ...a.data(),
            id: a.id,
          }));
          setLists(lists);
          return Promise.all([
            getTodosCreatedByUser(user.uid),
            ...lists.map((a) => getTodosByList(a.id)),
          ])
            .then((querySnapshots) => {
              setRefresh(false);
              const todos = uniq(
                querySnapshots.flatMap((a) =>
                  a.docs.map((a) => {
                    return fromFsType(a.data());
                  })
                )
              ).sort((a, b) => {
                return (
                  (a.listIndex === undefined) - (b.listIndex === undefined) ||
                  +(a.listIndex > b.listIndex) ||
                  -(a.listIndex < b.listIndex)
                );
              });
              setTodos(todos.filter((a) => !a.done));
              setDoneTodos(todos.filter((a) => a.done));
              setLoadingList(false);
            })
            .catch((e) => {});
        })
        .catch((e) => {});
    }
  }, [refresh, user]);

  const [lists, setLists] = useState([]);
  const [showCreateList, setShowCreateList] = useState(false);
  const [newListName, setNewListName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [listsEmails, setListsEmails] = useState([]);
  const [newEmailValidation, setNewEmailValidation] = useState(true);

  const [editList, setEditList] = useState([false, {}]);
  const [newEditEmail, setNewEditEmail] = useState("");
  const setEditListsEmails = (array) =>
    setEditList([editList[0], { ...editList[1], readers: array }]);
  const [newEditEmailValidation, setNewEditEmailValidation] = useState(true);
  const [showDelListModal, setDelListModal] = useState(false);
  const createList = async (list) => {
    const newList = await createListRemote(list);
    setLists([...lists, { ...list, id: newList }]);
  };

  const deleteList = async (listId) => {
    await deleteListRemote(listId);
    setLists(lists.filter((a) => a.id !== listId));
  };

  const patchList = async (list) => {
    const updatedList = await updateList(list);
    setLists(lists.map((a) => (a.id === list.id ? list : a)));
  };
  // get groups

  const previousTodos = usePrevious({ todos });

  // sync lists

  // sync todos
  useEffect(() => {
    const timestamp = moment().format("DD.MM.YYYY HH:mm");
    try {
      // check for updates and update db
      const updatedTodo = todos.filter(
        (a) => !previousTodos.todos.map((a) => a.id).includes(a.id)
      );
      if (updatedTodo && updatedTodo.length > 0) {
        updateTodo(updatedTodo[0]).catch((e) => {});
        if (
          updatedTodo[0].list &&
          lists.find((a) => a.id === updatedTodo[0].list).readers.length > 1
        ) {
          Promise.all(
            lists
              .find((a) => a.id === updatedTodo[0].list)
              .readers.filter((email) => email !== user.email)
              .map(getUserByEmail)
          )
            .then((querySnapshots) => {
              const users = querySnapshots.flatMap((a) =>
                a.docs.map((a) => a.id)
              );
              return Promise.all(
                users.map((uid) =>
                  addActivityFeedItem(uid, {
                    ...updatedTodo[0],
                    listname:
                      lists.filter((a) => a.id === group)[0] &&
                      lists.filter((a) => a.id === group)[0].name,
                    timestamp: new Date(),
                  })
                )
              )
                .then(() => console.log("log"))
                .catch((e) => console.log("e", e));
            })
            .catch((e) => {});
        }
        return;
      }
      const updates = todos.filter((a) => !previousTodos.todos.some(equals(a)));
      if (updates && updates.length > 0) {
        Promise.all(updates.map(updateTodo)).catch((e) => {});
      } else {
        const doneTodo =
          previousTodos &&
          previousTodos.todos.length > 0 &&
          previousTodos.todos.filter(
            (a) => !todos.map((a) => a.id).includes(a.id)
          );
        if (doneTodo && doneTodo.length > 0) {
          // if not in completedTodos then delete
          if (doneTodos.map((a) => a.id).includes(doneTodo[0].id)) {
            updateTodo({
              ...doneTodo[0],
              timestamp,
              done: true,
              completedBy: user.displayName || user.email,
            }).catch((e) => {});

            // here
            if (
              doneTodo[0].list &&
              lists.find((a) => a.id === doneTodo[0].list).readers.length > 1
            ) {
              Promise.all(
                lists
                  .find((a) => a.id === doneTodo[0].list)
                  .readers.filter((email) => email !== user.email)
                  .map(getUserByEmail)
              )
                .then((querySnapshots) => {
                  const users = querySnapshots.flatMap((a) =>
                    a.docs.map((a) => a.id)
                  );
                  return Promise.all(
                    users.map((uid) =>
                      addActivityFeedItem(uid, {
                        ...doneTodo[0],
                        timestamp,
                        done: true,
                        completedBy: user.displayName || user.email,
                        listname:
                          lists.filter((a) => a.id === group)[0] &&
                          lists.filter((a) => a.id === group)[0].name,
                      })
                    )
                  );
                })
                .catch((e) => {});
            }
          } else {
            deleteTodoRemote(doneTodo[0].id).catch((e) => {});
          }
        }
      }
    } catch (e) {}
  }, [todos]);

  const [text, handleText] = useState("");
  const addTodo = (todo) =>
    setTodos([
      {
        ...todo,
        createdAt: moment().toISOString(),
        createdBy: user.uid,
        createdByName: user.displayName || user.email,
        listname:
          lists.filter((a) => a.id === group)[0] &&
          lists.filter((a) => a.id === group)[0].length > 0
            ? lists.filter((a) => a.id === group)[0].name
            : "inbox",
      },
      ...todos,
    ]);
  const deleteTodo = (id) => {
    setTodos(todos.filter((a) => a.id !== id));
    setDoneTodos(doneTodos.filter((a) => a.id !== id));
  };
  const completeTodo = (id) => {
    toggle(true);
    setTodos(todos.filter((a) => a.id !== id));
    setDoneTodos([...todos.filter((a) => a.id === id), ...doneTodos]);
    setShowDetail(
      showDetail &&
        showDetail[1] && [
          showDetail[0],
          { ...todos.filter((a) => a.id === id)[0], done: true },
        ]
    );
  };
  const uncompleteTodo = ({ id, ...todo }) => {
    setDoneTodos(doneTodos.filter((a) => a.id !== id));
    setTodos([...todos, { id, ...todo, done: false }]);
    setShowDetail(
      showDetail &&
        showDetail[1] && [
          showDetail[0],
          { ...todos.filter((a) => a.id === id)[0], done: false },
        ]
    );
  };
  const [showCompleted, setShowCompleted] = useLocalStorage(
    "showCompleted",
    false
  );
  const [showDetail, _setShowDetail] = useState([null, {}]);
  const setShowDetail = ([show, detail]) => {
    _setShowDetail([show, detail]);
    if (detail && detail.remindDate) {
      let remDate = detail.remindDate;
      setTempRemindTime(moment(remDate, "DD.MM.YYYY HH:mm").format("HH:mm"));
    }
  };
  const [showDelModal, setDelModal] = useState(false);
  const [showGroups, setShowGroups] = useState(false);
  const [focus, setFocus] = useState(null);
  const [focus1, setFocus1] = useState(null);
  const [search, setSearch] = useState("");
  const [subTodoText, setSubTodoText] = useState("");
  const [group, setGroupOnly] = useState("inbox");
  const setGroup = (group) => {
    window.scroll(0, 0);
    setGroupOnly(group);
    setShowCompleted(false);
  };
  const [showLogout, setShowLogout] = useState(false);
  const sideInput = useRef("");
  const onSideInputEnter = () => {
    sideInput.current.blur();
  };

  const moveTodo = (dragIndex, hoverIndex) => {
    const subsetOfTodos = todos
      .filter((a) => {
        return group === "inbox" && !a.list
          ? true
          : group === "favourites"
          ? a.favourited
          : group === "today"
          ? a.date && moment(a.date, "DD.MM.YYYY").isSame(new Date(), "day")
            ? true
            : false
          : group === "week"
          ? a.date &&
            moment(a.date, "DD.MM.YYYY").diff(
              moment().startOf("week"),
              "week"
            ) < 1
            ? true
            : false
          : a.list === group
          ? true
          : false;
      })
      .filter((a) => {
        if (search === "") return true;
        if (a.text.match(search)) return true;
        return false;
      });
    const prevTodo = subsetOfTodos[hoverIndex];
    const dragCard = subsetOfTodos[dragIndex];
    const subsetOfTodosWithIndex = subsetOfTodos.map((a, i) => ({
      ...a,
      listIndex: i + 1,
    }));
    const newSubsetOfTodosWithIndex = [
      ...subsetOfTodosWithIndex
        .filter((a) => a.id !== subsetOfTodosWithIndex[dragIndex].id)
        .slice(0, hoverIndex),
      { ...subsetOfTodosWithIndex[dragIndex], listIndex: hoverIndex },
      ...subsetOfTodosWithIndex
        .filter((a) => a.id !== subsetOfTodosWithIndex[dragIndex].id)
        .slice(hoverIndex, subsetOfTodosWithIndex.length),
    ].map((a, i) => ({ ...a, listIndex: i + 1 }));
    const prevTodoIndex = todos.findIndex((a) => a.id === prevTodo.id);
    const updatedTodosWithListIndex = todos.map((a) => {
      const td = newSubsetOfTodosWithIndex.filter((b) => b.id === a.id);
      if (td && td.length > 0) {
        return td[0];
      }
      return a;
    });
    setTodos([
      ...updatedTodosWithListIndex
        .filter((a) => a.id !== dragCard.id)
        .slice(0, prevTodoIndex),
      { ...dragCard, listIndex: hoverIndex },
      ...updatedTodosWithListIndex
        .filter((a) => a.id !== dragCard.id)
        .slice(prevTodoIndex, todos.length),
    ]);
  };

  const [tempRemindTime, setTempRemindTime] = useState("9:00");
  const [notificationPem, showNotificationPem] = useState("granted");
  useEffect(() => {
    try {
      showNotificationPem(Notification.permission);
    } catch (e) {}
  }, []);

  const [showUserProfile, setShowUserProfile] = useState(false);
  const [tempUserName, setTempUserName] = useState("");
  const [showDelUser, setShowDelUser] = useState(false);

  const [stripeError, setStripeError] = useState("");
  const [showCoinModal, setShowCoinModal] = useState(false);

  return (
    <div className="App">
      <Modal open={showDelUser} toggle={setShowDelUser}>
        <ModalBody style={{ textAlign: "center" }}>
          <button
            style={{ float: "right", display: "inline" }}
            onClick={() => {
              setShowDelUser(false);
              setShowUserProfile(true);
            }}
          >
            X
          </button>
          <div>
            <div style={{ width: "30%", display: "inline-block" }}>
              <img src={robot} style={{ width: "100%" }} />
            </div>
            <div
              style={{ width: "70%", fontSize: 13, display: "inline-block" }}
            >
              <p>
                Are you sure you would like to delete your acconut at GreatList,
                I will miss you, but thanks for your time here!
              </p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              marginLeft: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <Button
              theme="danger"
              onClick={() => {
                deleteUser(user.uid);
              }}
            >
              Delete my Account
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal open={showUserProfile} toggle={setShowUserProfile}>
        <ModalBody>
          <div style={{ width: "100%", marginBottom: 20 }}>
            <h3 style={{ display: "inline" }}>My account</h3>
            <button
              style={{ float: "right", display: "inline" }}
              onClick={() => {
                setShowUserProfile(false);
              }}
            >
              X
            </button>
          </div>
          <ListGroup>
            <ListGroupItem>
              <label>Change username</label>
              <br />
              <FormInput
                value={tempUserName || displayUser.displayName}
                style={{ width: "70%", display: "inline" }}
                onChange={(e) => {
                  setTempUserName(e.target.value);
                }}
              />
              <Button
                theme="success"
                disabled={!tempUserName !== displayUser.displayName}
                style={{ float: "right" }}
              >
                Save
              </Button>
              <div style={{ marginTop: 20 }}>
                <img
                  src={displayUser.photoURL || user.photoURL || robot}
                  style={{
                    boxShadow: "0px 3px 15px rgba(0,0,0,0.1)",
                    float: "left",
                    width: 50,
                    height: 50,
                    borderRadius: 50,
                  }}
                ></img>
                <label
                  className="btn btn-success"
                  style={{ display: "block", float: "right", marginLeft: 50 }}
                >
                  Change profile image
                  <input
                    type="file"
                    id="single"
                    onChange={(e) => {
                      if (
                        FileReader &&
                        e.target.files &&
                        e.target.files.length
                      ) {
                        if (e.target.files[0].size < 1000000) {
                          var fr = new FileReader();
                          fr.onload = function (e) {
                            const blob = new Blob([e.target.result], {
                              type: "image/png",
                            });
                            updateUserImage(user.uid, blob)
                              .then((a) => {
                                setUserImageState(false);
                                console.log("success");
                              })
                              .catch((a) => console.log("fail"));
                          };
                          fr.readAsArrayBuffer(e.target.files[0]);
                        }
                      }
                    }}
                    accept="image/png, image/jpeg"
                  />
                </label>
              </div>
            </ListGroupItem>
            <ListGroupItem>
              <label></label>
              <div>
                <div style={{ width: "30%", display: "inline-block" }}>
                  <img src={robot} style={{ width: "100%" }} />
                </div>
                <div
                  style={{
                    width: "70%",
                    fontSize: 13,
                    display: "inline-block",
                  }}
                >
                  <p>
                    Hey there! Are you enjoying GreatList? You can help me keep
                    it running by donating and sending me your feature
                    suggestions, I promise I will reply to them and get round to
                    building them as soon as I can!
                  </p>
                </div>
              </div>
              <div style={{ width: "100%", textAlign: "center" }}>
                <Button
                  theme="primary"
                  id="checkout-button-sku_Ftxo9LOrhFYo3i"
                  role="link"
                  onClick={() => {
                    const stripe = window.Stripe(
                      "pk_live_88Bo8zyBkQYMElnVyHj5NwX500FP0TKz27"
                    );

                    stripe
                      .redirectToCheckout({
                        items: [{ sku: "sku_Ftxo9LOrhFYo3i", quantity: 1 }],
                        customerEmail: user.email,
                        clientReferenceId: user.uid,
                        successUrl: "https://greatlist.io",
                        cancelUrl: "https://greatlist.io",
                      })
                      .then((result) => {
                        if (result.error) {
                          // If `redirectToCheckout` fails due to a browser or network
                          // error, display the localized error message to your customer.
                          setStripeError(result.error.message);
                        }
                      });
                  }}
                >
                  Donate to GreatList
                </Button>
              </div>

              {stripeError && <Alert theme="warning">{stripeError}</Alert>}
            </ListGroupItem>
            <ListGroupItem>
              <label></label>
              <p style={{ display: "inline", lineHeight: 3 }}>
                I would like to delete my account
              </p>
              <Button
                onClick={() => {
                  setShowDelUser(true);
                  setShowUserProfile(false);
                }}
                theme="warning"
                style={{ float: "right" }}
              >
                Delete
              </Button>
            </ListGroupItem>
          </ListGroup>
        </ModalBody>
      </Modal>

      {notificationPem === "default" && !isMobile ? (
        <div
          style={{
            position: "fixed",
            padding: 12,
            borderBottomLeftRadius: 12,
            borderBottomRightRadius: 12,
            transform: "translateX(-50%)",
            backgroundColor: "white",
            boxShadow:
              "0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1)",
            top: 0,
            zIndex: 10000,
            left: "50%",
            transformX: "-50%",
          }}
        >
          <button
            onClick={() => {
              askForPermissioToReceiveNotifications()
                .then((token) => {
                  return setUserPushToken(user.uid, token);
                })
                .then(() => {
                  showNotificationPem(Notification.permission);
                })
                .catch((e) => {
                  showNotificationPem(Notification.permission);
                });
            }}
          >
            Turn notifications on to receive reminders
          </button>
          <button
            style={{ marginLeft: 20 }}
            onClick={() => {
              showNotificationPem(false);
            }}
          >
            X
          </button>
        </div>
      ) : null}
      <button
        onClick={() => {
          setRefresh(true);
        }}
        invalid={refresh}
        style={{
          position: "absolute",
          padding: 10,
          fontSize: 20,
          cursor: "pointer",
          backgroundColor: "rgba(255,255,255,0.3)",
          top: 0,
          right: 0,
        }}
      >
        {refresh ? (
          <i
            style={{ color: "white", cursor: "pointer" }}
            className="fas fa-sync"
          ></i>
        ) : (
          <i className="fas fa-sync" style={{ color: "rgb(90, 97, 105)" }} />
        )}
      </button>
      <button
        onClick={() => {
          if (!["inbox", "favourites", "today", "week"].includes(group)) {
            setEditList([
              true,
              group && lists.filter((a) => a.id === group)[0],
            ]);
          }
        }}
        style={{
          position: "absolute",
          padding: 10,
          fontSize: 20,
          cursor: ["inbox", "favourites", "today", "week"].includes(group)
            ? "auto"
            : "pointer",
          backgroundColor: "rgba(255,255,255,0.3)",
          top: 0,
          right: 40,
        }}
      >
        <i
          className="fas fa-user-plus"
          style={{
            color: ["inbox", "favourites", "today", "week"].includes(group)
              ? "rgba(90, 97, 105, 0.3)"
              : "rgb(90, 97, 105)",
          }}
        />
      </button>
      <div
        style={{
          position: "absolute",
          fontSize: 20,
          top: 0,
          right: 85,
          border: 0,
          borderRadius: 0,
        }}
      >
        <Dropdown
          toggle={() => {
            if (!activityfeedShow && activityfeed && activityfeed.length > 0) {
              // set seen
              setSeenActivity(activityfeed);
            }
            setActivityFeedShow(!activityfeedShow);
          }}
          open={activityfeedShow}
        >
          <DropdownToggle
            style={{
              padding: 14,
              fontSize: 20,
              backgroundColor: "rgba(255,255,255,0.3)",
              border: 0,
              borderRadius: 0,
              boxShadow: 0,
            }}
          >
            {activityfeed.filter((a) => !a.seen).length > 0 && (
              <div
                style={{
                  position: "absolute",
                  padding: 2,
                  paddingLeft: 5,
                  paddingRight: 5,
                  backgroundColor: "rgba(255, 214, 66, 1)",
                  top: 5,
                  fontWeight: "bold",
                  right: 5,
                  fontSize: 11,
                  borderRadius: 10,
                }}
              >
                {activityfeed.filter((a) => !a.seen).length}
              </div>
            )}
            <i className="fas fa-bell" style={{ color: "rgb(90, 97, 105)" }} />
          </DropdownToggle>
          <DropdownMenu className="activity-feed">
            {activityfeed && activityfeed.length > 0 ? (
              activityfeed.map(
                ({
                  text,
                  type,
                  timestamp,
                  completedBy,
                  createdByName,
                  done,
                  listname,
                  list,
                }) => {
                  return type === "reminder" ? (
                    <DropdownItem style={{ whiteSpace: "unset" }}>
                      <div style={{ position: "relative" }}>
                        <span style={{ fontSize: 12 }}>Reminder: {text}</span>
                        <div
                          style={{
                            color: "grey",
                            position: "absolute",
                            right: 0,
                            bottom: -10,
                            fontSize: 10,
                          }}
                        >
                          {moment(timestamp, "DD.MM.YYYY HH.mm").fromNow()}
                        </div>
                      </div>
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      onClick={() => {
                        setGroup(list);
                      }}
                      style={{ whiteSpace: "unset" }}
                    >
                      <div style={{ position: "relative" }}>
                        <span style={{ fontSize: 12 }}>
                          {`${
                            done
                              ? completedBy || "greatlister"
                              : createdByName || "greatlister"
                          } ${done ? "completed" : "created"} ${text}`}{" "}
                          in {listname}
                        </span>
                        <span
                          style={{
                            color: "grey",
                            position: "absolute",
                            right: 0,
                            bottom: -10,
                            fontSize: 10,
                          }}
                        >
                          {timestamp.length < 17
                            ? moment(timestamp, "DD.MM.YYYY HH.mm").fromNow()
                            : moment(timestamp).fromNow()}
                        </span>
                      </div>
                    </DropdownItem>
                  );
                }
              )
            ) : (
              <DropdownItem>No activity yet.</DropdownItem>
            )}
          </DropdownMenu>
        </Dropdown>
      </div>
      <button
        onClick={() => {
          if (coins > 0) {
            setGroup(backlogId);
          } else {
            setShowCoinModal(true);
          }
        }}
        style={{
          position: "absolute",
          padding: 10,
          fontSize: 20,
          cursor: "pointer",
          backgroundColor: "rgba(255,255,255,0.3)",
          top: 0,
          right: 130,
        }}
      >
        {
          <div>
            {coins}{" "}
            <i
              style={{
                color: coins > 0 ? "rgba(255, 214, 66, 1)" : "rgb(90, 97, 105)",
                cursor: "pointer",
              }}
              className="fas fa-coins"
            ></i>
          </div>
        }
      </button>
      <Modal open={showDelModal} toggle={setDelModal}>
        <ModalBody>
          "{showDetail[1].text}" will be deleted forever.
          <p>You will not be able to undo this action.</p>
          <Button
            style={{ float: "right" }}
            theme="light"
            onClick={() => setDelModal(false)}
          >
            Cancel
          </Button>
          <Button
            style={{ float: "right", marginRight: 20 }}
            theme="danger"
            onClick={() => {
              setShowDetail([null, {}]);
              deleteTodo(showDetail[1].id);
              setDelModal(false);
            }}
          >
            Delete
          </Button>
        </ModalBody>
      </Modal>

      <Modal open={showCoinModal} toggle={setShowCoinModal}>
        <ModalBody>
          <button
            style={{ float: "right", display: "inline" }}
            onClick={() => {
              setShowCoinModal(false);
            }}
          >
            X
          </button>
          <div style={{ textAlign: "center" }}>
            <div style={{ width: "100%", display: "inline-block" }}>
              <img src={robot} style={{ width: "50%" }} />
            </div>
            <div
              style={{ width: "100%", fontSize: 15, display: "inline-block" }}
            >
              <p>
                Hey there! Are you enjoying GreatList? You can help me keep it
                running by donating and also shape the direction of greatlist by
                adding items to our backlog! One donation earns one coin which
                allows you to add a backlog item. I appreciate your support so
                much and promise to get to your item as soon as I can!
              </p>
            </div>
            <Button
              onClick={() => {
                setShowCoinModal(false);
                setGroup(backlogId);
              }}
              theme="success"
              style={{ marginBottom: 10 }}
            >
              View GreatLists backlog
            </Button>
            <Button
              theme="primary"
              id="checkout-button-sku_FsoapjSJPWRlgW"
              role="link"
              onClick={() => {
                const stripe = window.Stripe(
                  "pk_live_88Bo8zyBkQYMElnVyHj5NwX500FP0TKz27"
                );

                stripe.redirectToCheckout({
                  items: [{ sku: "sku_Ftxo9LOrhFYo3i", quantity: 1 }],
                  customerEmail: user.email,
                  clientReferenceId: user.uid,
                  successUrl: "https://greatlist.io",
                  cancelUrl: "https://greatlist.io",
                });
              }}
            >
              Donate to GreatList to Earn a Coin
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal open={showDelListModal} toggle={setDelListModal}>
        <ModalBody>
          List "{showDetail[1].name}" will be deleted forever.
          <p>You will not be able to undo this action.</p>
          <Button
            style={{ float: "right" }}
            theme="light"
            onClick={() => {
              setDelListModal(false);
              setEditList([true, editList[1]]);
            }}
          >
            Cancel
          </Button>
          <Button
            style={{ float: "right", marginRight: 20 }}
            theme="danger"
            onClick={() => {
              deleteList(editList[1].id);
              setEditList([false, {}]);
              setShowGroups("inbox");
              setDelListModal(false);
            }}
          >
            Delete
          </Button>
        </ModalBody>
      </Modal>

      <Modal open={editList[0]} toggle={(a) => setEditList([a, editList[1]])}>
        <ModalBody>
          <button
            style={{ float: "right", position: "relative", margin: 15 }}
            onClick={() => {
              setDelListModal(true);
              setEditList([false, editList[1]]);
            }}
          >
            <i class="fas fa-trash"></i>
          </button>

          <div style={{ width: "80%", position: "relative" }}>
            <FormInput
              value={(editList[1] && editList[1].name) || ""}
              disabled={
                editList[1].admins && !editList[1].admins.includes(user.email)
              }
              placeholder="name"
              onChange={(e) => {
                setEditList([true, { ...editList[1], name: e.target.value }]);
              }}
              style={{
                fontSize: 25,
                paddingLeft: 10,
                marginLeft: -10,
                border: 0,
              }}
            />
            <i
              style={{
                float: "right",
                position: "absolute",
                top: 18,
                right: 20,
              }}
              class="fas fa-pen"
            ></i>
          </div>
          {editList[1].admins && editList[1].admins.includes(user.email) ? (
            <div>
              Add members
              <FormInput
                value={newEditEmail}
                placeholder="email"
                type="email"
                onChange={(e) => {
                  setNewEditEmail(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && isEmail(e.target.value)) {
                    setEditListsEmails([
                      ...editList[1].readers,
                      e.target.value,
                    ]);
                    inviteUser(e.target.value, user.email, editList[1].name);
                    setNewEditEmail("");
                    setNewEditEmailValidation(true);
                  } else if (e.key === "Enter" && !isEmail(e.target.value)) {
                    setNewEditEmailValidation(false);
                  }
                }}
              />
            </div>
          ) : (
            <div>Members</div>
          )}
          {!newEditEmailValidation && (
            <Alert theme="danger">Not a valid email address</Alert>
          )}
          <ListGroup style={{ marginTop: 20 }}>
            {editList[1].readers &&
              editList[1].readers.map((email) => (
                <ListGroupItem>
                  <span>{email}</span>
                  {editList[1].admins &&
                    editList[1].admins.includes(user.email) && (
                      <button
                        style={{ float: "right" }}
                        onClick={() =>
                          setEditListsEmails(
                            editList[1].readers.filter((a) => a !== email)
                          )
                        }
                      >
                        <i
                          className="fas fa-times"
                          style={{ color: "lightgray", marginRight: 5 }}
                        />{" "}
                        {email !== user.email ? "Remove" : "Leave"}
                      </button>
                    )}
                  <span style={{ float: "right", marginRight: 10 }}>
                    {editList[1].admins.includes(email) ? (
                      <Badge theme="primary">Admin</Badge>
                    ) : (
                      <Badge theme="light">Member</Badge>
                    )}
                  </span>
                </ListGroupItem>
              ))}
          </ListGroup>

          <div style={{ marginTop: 20 }}>
            {editList[1].admins && editList[1].admins.includes(user.email) && (
              <Button
                style={{ float: "right" }}
                theme="success"
                onClick={() => {
                  if (editList[1].name.length > 0) {
                    // side effect here invite users in groups via email cloud function
                    patchList(editList[1]);
                    setEditList([false, {}]);
                  }
                }}
              >
                Save
              </Button>
            )}
            <Button
              style={{ float: "right", marginRight: 20 }}
              theme="light"
              onClick={() => setEditList([false, {}])}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal open={showCreateList} toggle={setShowCreateList}>
        <ModalBody>
          Create list
          <div style={{ width: "100%", position: "relative" }}>
            <FormInput
              value={newListName}
              placeholder="name"
              style={{
                fontSize: 25,
                border: 0,
                marginBottom: 20,
              }}
              onChange={(e) => {
                setNewListName(e.target.value);
              }}
            />
            <i
              style={{
                float: "right",
                position: "absolute",
                top: 18,
                right: 20,
              }}
              class="fas fa-pen"
            ></i>
          </div>
          Add members
          <FormInput
            value={newEmail}
            placeholder="email"
            type="email"
            onChange={(e) => {
              setNewEmail(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && isEmail(e.target.value)) {
                setListsEmails([...listsEmails, e.target.value]);
                setNewEmail("");
                setNewEmailValidation(true);
              } else if (e.key === "Enter" && !isEmail(e.target.value)) {
                setNewEmailValidation(false);
              }
            }}
          />
          {!newEmailValidation && (
            <Alert theme="danger">Not a valid email address</Alert>
          )}
          <ListGroup style={{ marginTop: 20 }}>
            {listsEmails.map((email) => (
              <ListGroupItem>
                <span>{email}</span>
                <button
                  style={{ float: "right" }}
                  onClick={() =>
                    setListsEmails(listsEmails.filter((a) => a !== email))
                  }
                >
                  <i
                    className="fas fa-times"
                    style={{ color: "lightgray", marginRight: 5 }}
                  />
                  Remove
                </button>
              </ListGroupItem>
            ))}
          </ListGroup>
          <div style={{ marginTop: 20 }}>
            <Button
              style={{ float: "right" }}
              theme="success"
              onClick={() => {
                if (newListName.length > 0) {
                  // side effect here invite users in groups via email cloud function
                  if (newEmail.length > 0 && isEmail(newEmail)) {
                    createList({
                      name: newListName,
                      admins: [user.email],
                      readers: [user.email, newEmail, ...listsEmails],
                    });
                    setNewListName("");
                    setListsEmails([]);
                    setShowCreateList(false);
                    return;
                  } else if (newEmail.length > 0 && !isEmail(newEmail)) {
                    setNewEmailValidation(false);
                    return;
                  }
                  createList({
                    name: newListName,
                    admins: [user.email],
                    readers: [user.email, ...listsEmails],
                  });
                  setNewListName("");
                  setListsEmails([]);
                  setShowCreateList(false);
                }
              }}
            >
              Save
            </Button>
            <Button
              style={{ float: "right", marginRight: 20 }}
              theme="light"
              onClick={() => setShowCreateList(false)}
            >
              Cancel
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <div
        className={
          showDetail[0] === null
            ? "display-false"
            : showDetail[0] === true
            ? "show-side-bar"
            : "hide-side-bar"
        }
        style={{ padding: 10 }}
      >
        <div style={{ margin: 10, display: "flex", flex: 1 }}>
          <FormCheckbox
            checked={showDetail[1].done}
            onChange={(e) => {
              completeTodo(showDetail[1].id);
              setShowDetail([showDetail[0], { ...showDetail[1], done: true }]);
            }}
          />
          <TextareaAutosize
            ref={sideInput}
            value={showDetail[1].text}
            style={{ border: "none" }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && showDetail[1].text.length > 0) {
                e.preventDefault();
                setTodos(
                  todos.map((a) =>
                    a.id === showDetail[1].id
                      ? { ...a, text: showDetail[1].text }
                      : a
                  )
                );
                onSideInputEnter();
              }
            }}
            onChange={(e) => {
              setShowDetail([
                showDetail[0],
                { ...showDetail[1], text: e.target.value },
              ]);
            }}
          />
          <button
            onClick={() => {
              setShowDetail([
                showDetail[0],
                { ...showDetail[1], favourited: !showDetail[1].favourited },
              ]);
              setTodos(
                todos.map((a) =>
                  a.id === showDetail[1].id
                    ? { ...a, favourited: !a.favourited }
                    : a
                )
              );
            }}
            style={{ flex: "auto", flexDirection: "column" }}
          >
            {showDetail[1].favourited ? (
              <i style={{ color: "#FF0055" }} className="fas fa-star"></i>
            ) : (
              <i className="fas fa-star" style={{ color: "lightgray" }} />
            )}
          </button>
          <button
            style={{
              flex: "auto",
              flexDirection: "column",
              position: "relative",
              marginLeft: 8,
            }}
            onClick={() => setShowDetail([false, showDetail[1]])}
          >
            <i className="fas fa-caret-square-right"></i>
          </button>
        </div>
        <div>
          <ListGroup>
            <ListGroupItem>
              <i style={{ marginRight: 18 }} className="fas fa-calendar"></i>
              <span style={{ marginRight: 10 }}>Due on</span>
              <SingleDatePicker
                horizontalMargin={0}
                daySize={30}
                small
                displayFormat="MMM DD YYYY"
                numberOfMonths={1}
                placeholder="set due date"
                date={
                  showDetail[1] &&
                  showDetail[1].date &&
                  moment(showDetail[1].date, "DD.MM.YYYY")
                }
                onDateChange={(date) => {
                  if (date) {
                    setShowDetail([
                      true,
                      {
                        ...showDetail[1],
                        date: date.format("DD.MM.YYYY HH:mm"),
                      },
                    ]);
                    setTodos(
                      todos.map((a) =>
                        a.id === showDetail[1].id
                          ? { ...a, date: date.format("DD.MM.YYYY HH:mm") }
                          : a
                      )
                    );
                    return;
                  }
                  setShowDetail([
                    true,
                    { ...showDetail[1], date: null, remindDate: null },
                  ]);
                  setTodos(
                    todos.map((a) =>
                      a.id === showDetail[1].id
                        ? { ...a, date: null, remindDate: null }
                        : a
                    )
                  );
                }} // PropTypes.func.isRequired
                focused={focus} // PropTypes.bool
                onFocusChange={({ focused }) => setFocus(focused)} // PropTypes.func.isRequired
                id="your_unique_id" // PropTypes.string.isRequired,
              />
            </ListGroupItem>
            <ListGroupItem
              style={{
                pointerEvents:
                  showDetail[1] && showDetail[1].date ? "auto" : "none",
              }}
              className={
                showDetail[1] && showDetail[1].date ? "" : "coming-soon"
              }
            >
              {/* <i style={{ marginRight: 18 }} className="fas fa-calendar"></i> */}
              <FormCheckbox
                className="reminder-checkbox"
                disabled={!(showDetail[1] && showDetail[1].date)}
                checked={showDetail[1].remindDate}
                onChange={(e) => {
                  if (showDetail[1] && showDetail[1].date) {
                    if (!showDetail[1].remindDate) {
                      setShowDetail([
                        showDetail[0],
                        {
                          ...showDetail[1],
                          remindDate:
                            moment(
                              showDetail[1].date,
                              "DD.MM.YYYY HH:mm"
                            ).format("DD.MM.YYYY") +
                            " " +
                            "09:00",
                        },
                      ]);
                      setTodos(
                        todos.map((a) =>
                          a.id === showDetail[1].id
                            ? {
                                ...a,
                                remindDate:
                                  moment(
                                    showDetail[1].date,
                                    "DD.MM.YYYY HH:mm"
                                  ).format("DD.MM.YYYY") +
                                  " " +
                                  moment(
                                    showDetail[1].remindDate,
                                    "DD.MM.YYYY HH:mm"
                                  ).format("HH:mm"),
                              }
                            : a
                        )
                      );
                    } else {
                      setShowDetail([
                        showDetail[0],
                        { ...showDetail[1], remindDate: null },
                      ]);
                      setTodos(
                        todos.map((a) =>
                          a.id === showDetail[1].id
                            ? { ...a, remindDate: null }
                            : a
                        )
                      );
                    }
                  }
                }}
              />
              <span style={{ marginRight: 10 }}>Remind me</span>
              <SingleDatePicker
                horizontalMargin={0}
                daySize={30}
                small
                readOnly
                displayFormat="MMM DD YYYY"
                numberOfMonths={1}
                placeholder="set reminder"
                date={
                  showDetail[1] &&
                  showDetail[1].remindDate &&
                  moment(showDetail[1].remindDate, "DD.MM.YYYY HH:mm")
                }
                onDateChange={(date) => {
                  if (date) {
                    setShowDetail([
                      true,
                      {
                        ...showDetail[1],
                        remindDate:
                          date.format("DD.MM.YYYY") +
                          " " +
                          moment(
                            showDetail[1].remindDate,
                            "DD.MM.YYYY HH:mm"
                          ).format("HH:mm"),
                      },
                    ]);
                    setTodos(
                      todos.map((a) =>
                        a.id === showDetail[1].id
                          ? {
                              ...a,
                              remindDate:
                                date.format("DD.MM.YYYY") +
                                " " +
                                moment(
                                  showDetail[1].remindDate,
                                  "DD.MM.YYYY HH:mm"
                                ).format("HH:mm"),
                            }
                          : a
                      )
                    );
                    return;
                  }
                  setShowDetail([true, { ...showDetail[1], date: null }]);
                  setTodos(
                    todos.map((a) =>
                      a.id === showDetail[1].id ? { ...a, remindDate: null } : a
                    )
                  );
                }} // PropTypes.func.isRequired
                focused={focus1} // PropTypes.bool
                onFocusChange={({ focused }) => setFocus1(focused)} // PropTypes.func.isRequired
                id="abcefg" // PropTypes.string.isRequired,
              />
              {showDetail[1] && showDetail[1].date && (
                <FormInput
                  style={{
                    width: 60,
                    padding: 5,
                    display: "inline-block",
                    borderRadius: 2,
                    border: "1px solid #dbdbdb",
                    marginLeft: 10,
                  }}
                  value={tempRemindTime}
                  onChange={(e) => {
                    e.stopPropagation();
                    if (e.target.value) {
                      setTempRemindTime(e.target.value);
                    }
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                    if (e.key === "Enter") {
                      if (
                        moment(tempRemindTime, "HH:mm").format("HH:mm") !==
                        "Invalid date"
                      ) {
                        setShowDetail([
                          true,
                          {
                            ...showDetail[1],
                            remindDate:
                              moment(
                                showDetail[1].remindDate,
                                "DD.MM.YYYY HH:mm"
                              ).format("DD.MM.YYYY") +
                              " " +
                              moment(tempRemindTime, "HH:mm").format("HH:mm"),
                          },
                        ]);
                        setTodos(
                          todos.map((a) =>
                            a.id === showDetail[1].id
                              ? {
                                  ...a,
                                  remindDate:
                                    moment(
                                      showDetail[1].remindDate,
                                      "DD.MM.YYYY HH:mm"
                                    ).format("DD.MM.YYYY") +
                                    " " +
                                    moment(tempRemindTime, "HH:mm").format(
                                      "HH:mm"
                                    ),
                                }
                              : a
                          )
                        );
                        setTempRemindTime(
                          moment(tempRemindTime, "HH:mm").format("HH:mm")
                        );
                      } else {
                        setTempRemindTime("9:00");
                      }
                      setFocus1(false);
                    }
                  }}
                />
              )}
            </ListGroupItem>
            <Tooltip
              delay={500}
              placement="right"
              open={comingSoon}
              target="#TooltipExample"
              toggle={() => setComingSoon(!comingSoon)}
            >
              Coming Soon!
            </Tooltip>
            <ListGroupItem style={{ padding: 0 }}>
              <FormInput
                style={{ border: "none" }}
                placeholder="Add a subtask.."
                value={subTodoText}
                onChange={(e) => {
                  setSubTodoText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && subTodoText.length > 0) {
                    setSubTodoText("");
                    setTodos(
                      todos.map((a) =>
                        a.id === showDetail[1].id
                          ? {
                              ...a,
                              subtodos: [
                                ...a.subtodos,
                                { text: e.target.value, id: uuid() },
                              ],
                            }
                          : a
                      )
                    );
                    setShowDetail([
                      showDetail[0],
                      {
                        ...showDetail[1],
                        subtodos: [
                          ...showDetail[1].subtodos,
                          { text: e.target.value, id: uuid() },
                        ],
                      },
                    ]);
                  }
                }}
              ></FormInput>
            </ListGroupItem>
            {showDetail[1].subtodos && showDetail[1].subtodos.length > 0 ? (
              <ListGroupItem>
                <Linkify properties={{ target: "_blank" }}>
                  {showDetail[1].subtodos.map((subtodo) => (
                    <ListGroupItem
                      style={{
                        border: "none",
                        marginLeft: -10,
                        padding: "5px",
                      }}
                    >
                      {
                        <FormCheckbox
                          checked={subtodo.done}
                          onChange={(e) => {
                            e.stopPropagation();
                            setTodos(
                              todos.map((a) =>
                                a.id === showDetail[1].id
                                  ? {
                                      ...a,
                                      subtodos: a.subtodos.map((a) =>
                                        a.id === subtodo.id
                                          ? { ...a, done: true }
                                          : a
                                      ),
                                    }
                                  : a
                              )
                            );
                            setShowDetail([
                              true,
                              {
                                ...showDetail[1],
                                subtodos: showDetail[1].subtodos.map((a) =>
                                  a.id === subtodo.id ? { ...a, done: true } : a
                                ),
                              },
                            ]);
                          }}
                        />
                      }
                      <span
                        style={{
                          textDecoration: subtodo.done
                            ? "line-through"
                            : "none",
                        }}
                      >
                        {subtodo.text}
                      </span>
                    </ListGroupItem>
                  ))}
                </Linkify>
              </ListGroupItem>
            ) : null}

            <ListGroupItem style={{ padding: 0 }}>
              <TextareaAutosize
                style={{
                  marginLeft: 15,
                  marginRight: 15,
                  marginTop: 3,
                  minHeight: 50,
                }}
                placeholder="Add a note.."
                value={showDetail[1].note || ""}
                onChange={(e) => {
                  setTodos(
                    todos.map((a) =>
                      a.id === showDetail[1].id
                        ? { ...a, note: e.target.value }
                        : a
                    )
                  );
                  setShowDetail([
                    showDetail[0],
                    { ...showDetail[1], note: e.target.value },
                  ]);
                }}
              />
            </ListGroupItem>
            <ListGroupItem id="TooltipExample" className="coming-soon">
              Add a file
            </ListGroupItem>
          </ListGroup>
          <div
            style={{
              position: "absolute",
              bottom: isMobile ? 80 : 10,
              width: "100%",
            }}
          >
            <div style={{ position: "relative", width: "100%" }}>
              <button
                style={{ float: "left", position: "relative", marginLeft: 8 }}
                onClick={() => setShowDetail([false, showDetail[1]])}
              >
                <i className="fas fa-caret-square-right"></i>
              </button>
              <button
                style={{
                  float: "right",
                  position: "relative",
                  marginRight: 28,
                }}
                onClick={() => setDelModal(true)}
              >
                <i class="fas fa-trash"></i>
              </button>
            </div>
            <div style={{ fontSize: 10, textAlign: "center" }}>
              created {moment(showDetail[1].createdAt).toLocaleString()}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`left-side-bar ${showGroups ? "left-side-bar-show" : ""}`}
        style={{
          display: "inline-block",
          position: "fixed",
          width: showGroups ? "20vw" : "3vw",
        }}
      >
        <button
          onClick={() => setShowGroups(!showGroups)}
          style={{ padding: 15 }}
        >
          <i class="fas fa-bars"></i>
        </button>
        {!showGroups ? null : (
          <ListGroup>
            <ListGroupItem>
              <FormInput
                value={search}
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && search.length > 0) {
                    setShowGroups(false);
                  }
                }}
              ></FormInput>
            </ListGroupItem>
            <ListGroupItem>
              <Dropdown
                style={{ display: "inline" }}
                direction="left"
                open={showLogout}
                toggle={() => setShowLogout(!showLogout)}
              >
                <DropdownToggle
                  theme="info"
                  size="small"
                  style={{ display: "inline" }}
                >
                  <i
                    style={{ display: "inline" }}
                    class="fas fa-caret-down"
                  ></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => setShowUserProfile(true)}>
                    My Account
                  </DropdownItem>
                  <DropdownItem onClick={() => setGroup(backlogId)}>
                    Coming Soon
                  </DropdownItem>
                  <DropdownItem onClick={() => logout()}>Logout</DropdownItem>
                </DropdownMenu>
              </Dropdown>
              <img
                src={displayUser.photoURL || user.photoURL || robot}
                style={{
                  width: 30,
                  height: 30,
                  marginRight: 10,
                  borderRadius: 30,
                }}
              />
              {user && user.displayName ? user.displayName : "Greatlister"}
            </ListGroupItem>
            <ListGroupItem
              onClick={() => {
                if (isMobile) {
                  setShowGroups(false);
                }
                setGroup("inbox");
                if (showDetail[0] === true) {
                  setShowDetail([false, showDetail[1]]);
                }
              }}
              active={group === "inbox"}
            >
              Inbox
              <span
                style={{
                  backgroundColor:
                    group === "inbox" ? "#FFD542" : "rgba(0, 0, 0,0.15)",
                }}
                className="group-todo-number"
              >
                {todos.filter((a) => !a.list).length}
              </span>
            </ListGroupItem>
            <ListGroupItem
              onClick={() => {
                if (isMobile) {
                  setShowGroups(false);
                }
                setGroup("favourites");
                if (showDetail[0] === true) {
                  setShowDetail([false, showDetail[1]]);
                }
              }}
              active={group === "favourites"}
            >
              Favourited
              <span
                style={{
                  backgroundColor:
                    group === "favourites" ? "#FFD542" : "rgba(0, 0, 0,0.15)",
                }}
                className="group-todo-number"
              >
                {todos.filter((a) => a.favourited).length}
              </span>
            </ListGroupItem>
            <ListGroupItem
              onClick={() => {
                if (isMobile) {
                  setShowGroups(false);
                }
                setGroup("today");
                if (showDetail[0] === true) {
                  setShowDetail([false, showDetail[1]]);
                }
              }}
              active={group === "today"}
            >
              Today
              <span
                style={{
                  backgroundColor:
                    group === "today" ? "#FFD542" : "rgba(0, 0, 0,0.15)",
                }}
                className="group-todo-number"
              >
                {
                  todos.filter(
                    (a) =>
                      a.date &&
                      moment(a.date, "DD.MM.YYYY").isSame(new Date(), "day")
                  ).length
                }
              </span>
            </ListGroupItem>
            <ListGroupItem
              onClick={() => {
                if (isMobile) {
                  setShowGroups(false);
                }
                setGroup("week");
                if (showDetail[0] === true) {
                  setShowDetail([false, showDetail[1]]);
                }
              }}
              active={group === "week"}
            >
              Week
              <span
                style={{
                  backgroundColor:
                    group === "week" ? "#FFD542" : "rgba(0, 0, 0,0.15)",
                }}
                className="group-todo-number"
              >
                {
                  todos.filter(
                    (a) =>
                      a.date &&
                      moment(a.date, "DD.MM.YYYY").isSame(new Date(), "week")
                  ).length
                }
              </span>
            </ListGroupItem>
            {lists.map((g) => {
              return (
                <ListGroupItem
                  onClick={() => {
                    if (isMobile) {
                      setShowGroups(false);
                    }
                    setGroup(g.id);
                    if (showDetail[0] === true) {
                      setShowDetail([false, showDetail[1]]);
                    }
                  }}
                  active={group === g.id}
                >
                  {g.name}
                  <button
                    style={{ float: "right", marginLeft: 10 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setEditList([true, g]);
                    }}
                  >
                    <i style={{ color: "lightgrey" }} class="fas fa-cog"></i>
                  </button>
                  <span
                    style={{
                      backgroundColor:
                        group === g.id ? "#FFD542" : "rgba(0, 0, 0,0.15)",
                    }}
                    className="group-todo-number"
                  >
                    {
                      todos.filter((a) => {
                        return a.list === g.id;
                      }).length
                    }
                  </span>
                </ListGroupItem>
              );
            })}
            <ListGroupItem
              onClick={() => {
                setShowCreateList(true);
              }}
              style={{ cursor: "pointer" }}
            >
              + Create List
            </ListGroupItem>
          </ListGroup>
        )}
      </div>

      <div
        className={
          showDetail[0] === null
            ? "list"
            : showDetail[0] === true
            ? "shrink-list"
            : "grow-list"
        }
        style={{
          maxWidth:
            showGroups && showDetail[0]
              ? "54%"
              : showGroups || showDetail[0]
              ? "78%"
              : "95%",
          marginLeft: showGroups ? "20%" : "3%",
        }}
      >
        <div className="middle-section">
          <h1 style={{ display: "inline" }}>
            {["inbox", "favourites", "today", "week"].includes(group)
              ? "GreatList"
              : lists.filter((a) => a.id === group)[0] &&
                lists.filter((a) => a.id === group)[0].name}
          </h1>
          {group === backlogId &&
          !(
            lists.filter((a) => a.id === group) &&
            lists.filter((a) => a.id === group).length > 0 &&
            lists.filter((a) => a.id === group)[0].admins &&
            lists.filter((a) => a.id === group)[0].admins.includes(user.email)
          ) ? (
            <Backlog user={user} coins={coins || 0} />
          ) : (
            <div>
              <InputGroup style={{ marginBottom: 20, marginTop: 20 }}>
                <InputGroupAddon type="prepend">+</InputGroupAddon>
                <FormInput
                  placeholder="Add a to-do..."
                  value={text}
                  onChange={(e) => {
                    handleText(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && text.length > 0) {
                      if (
                        ["inbox", "favourites", "today", "week"].includes(group)
                      ) {
                        addTodo({
                          id: uuid(),
                          listIndex: 1,
                          subtodos: [],
                          text,
                        });
                      } else {
                        addTodo({
                          id: uuid(),
                          listIndex: 1,
                          subtodos: [],
                          list: group,
                          text,
                        });
                      }
                      handleText("");
                    }
                  }}
                />
              </InputGroup>
              <ListGroup>
                <div style={{ width: "100%", textAlign: "center" }}>
                  {(loadingList || refresh) && <Loader />}
                </div>
                {search && <div>Showing results for '{search}' </div>}
                <Linkify properties={{ target: "_blank" }}>
                  <DndProvider backend={HTML5Backend}>
                    {todos
                      .filter((a) => {
                        return group === "inbox" && !a.list
                          ? true
                          : group === "favourites"
                          ? a.favourited
                          : group === "today"
                          ? a.date &&
                            moment(a.date, "DD.MM.YYYY").isSame(
                              new Date(),
                              "day"
                            )
                            ? true
                            : false
                          : group === "week"
                          ? a.date &&
                            moment(a.date, "DD.MM.YYYY").diff(
                              moment().startOf("week"),
                              "week"
                            ) < 1
                            ? true
                            : false
                          : a.list === group
                          ? true
                          : false;
                      })
                      .filter((a) => {
                        if (search === "") return true;
                        if (a.text.match(search)) return true;
                        return false;
                      })
                      .map((todo, i) => {
                        return (
                          <Collapse
                            timeout={500}
                            open={!todo.done}
                            key={todo.id}
                          >
                            <ListItemDnd index={i} moveTodo={moveTodo}>
                              <ListGroupItem
                                style={{ display: "flex", cursor: "pointer" }}
                                onDoubleClick={() =>
                                  setShowDetail([!showDetail[0], todo])
                                }
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (isMobile) {
                                    setShowDetail([!showDetail[0], todo]);
                                    return;
                                  }
                                  return showDetail[0]
                                    ? setShowDetail([showDetail[0], todo])
                                    : null;
                                }}
                                active={
                                  showDetail[0] && showDetail[1].id === todo.id
                                }
                              >
                                <FormCheckbox
                                  checked={todo.done}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    completeTodo(todo.id);
                                  }}
                                ></FormCheckbox>
                                <div
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    display: "inline",
                                  }}
                                >
                                  {todo.text}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row-reverse",
                                    flex: "auto",
                                  }}
                                >
                                  <button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setTodos(
                                        todos.map((a) => {
                                          if (a.id === todo.id) {
                                            if (
                                              showDetail[0] &&
                                              showDetail[1].id === todo.id
                                            ) {
                                              setShowDetail([
                                                showDetail[0],
                                                {
                                                  ...showDetail[1],
                                                  favourited: !a.favourited,
                                                },
                                              ]);
                                            }
                                            return {
                                              ...a,
                                              favourited: !a.favourited,
                                            };
                                          }
                                          return a;
                                        })
                                      );
                                    }}
                                    style={{
                                      float: "right",
                                      marginLeft: "20px",
                                      marginRight: "20px",
                                    }}
                                  >
                                    {todo.favourited ? (
                                      <i
                                        style={{ color: "#FF0055" }}
                                        className="fas fa-star"
                                      ></i>
                                    ) : (
                                      <i
                                        className="fas fa-star"
                                        style={{ color: "lightgray" }}
                                      />
                                    )}
                                  </button>
                                  <span
                                    className="noselect"
                                    style={{
                                      marginRight: "40px",
                                      color:
                                        moment(todo.date, "DD.MM.YYYY") >
                                        moment()
                                          ? "#61C6FF"
                                          : "#FF0055",
                                      float: "right",
                                      marginLeft: "20px",
                                      marginRight: "20px",
                                    }}
                                  >
                                    {todo.date &&
                                      moment(todo.date, "DD.MM.YYYY").format(
                                        "DD.MM.YYYY"
                                      )}
                                  </span>
                                </div>
                              </ListGroupItem>
                            </ListItemDnd>
                          </Collapse>
                        );
                      })}
                  </DndProvider>
                </Linkify>
              </ListGroup>
              <Button
                theme="success"
                style={{ marginTop: 20, marginBottom: 20 }}
                onClick={() => setShowCompleted(!showCompleted)}
              >
                SHOW COMPLETED TO-DOS
              </Button>
              {showCompleted && (
                <ListGroup style={{ marginBottom: 30 }}>
                  <div style={{ display: "inline" }}>
                    {doneTodos.length
                      ? `${
                          doneTodos
                            .filter((a) => {
                              return group === "inbox" && !a.list
                                ? true
                                : group === "favourites"
                                ? a.favourited
                                : group === "today"
                                ? a.date &&
                                  moment(a.date, "DD.MM.YYYY").isSame(
                                    new Date(),
                                    "day"
                                  )
                                  ? true
                                  : false
                                : group === "week"
                                ? a.date &&
                                  moment(a.date, "DD.MM.YYYY").diff(
                                    moment().startOf("week"),
                                    "week"
                                  ) < 1
                                  ? true
                                  : false
                                : a.list === group
                                ? true
                                : false;
                            })
                            .filter((a) => {
                              if (search === "") return true;
                              if (a.text.match(search)) return true;
                              return false;
                            }).length
                        } 👏`
                      : null}
                  </div>
                  {doneTodos
                    .filter((a) => {
                      return group === "inbox" && !a.list
                        ? true
                        : group === "favourites"
                        ? a.favourited
                        : group === "today"
                        ? a.date &&
                          moment(a.date, "DD.MM.YYYY").isSame(new Date(), "day")
                          ? true
                          : false
                        : group === "week"
                        ? a.date &&
                          moment(a.date, "DD.MM.YYYY").diff(
                            moment().startOf("week"),
                            "week"
                          ) < 1
                          ? true
                          : false
                        : a.list === group
                        ? true
                        : false;
                    })
                    .filter((a) => {
                      if (search === "") return true;
                      if (a.text.match(search)) return true;
                      return false;
                    })
                    .map((todo) => {
                      return (
                        <ListGroupItem
                          style={{ display: "flex" }}
                          onDoubleClick={() =>
                            setShowDetail([!showDetail[0], todo])
                          }
                          onClick={() => {
                            if (isMobile) {
                              setShowDetail([!showDetail[0], todo]);
                              return;
                            }
                            return showDetail[0]
                              ? setShowDetail([showDetail[0], todo])
                              : null;
                          }}
                          active={showDetail[0] && showDetail[1].id === todo.id}
                        >
                          <FormCheckbox
                            className="blabla"
                            onChange={(e) => {
                              e.stopPropagation();
                              uncompleteTodo(todo);
                            }}
                            checked={true}
                          />
                          <div
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "inline",
                              textDecoration: "line-through",
                            }}
                          >
                            {todo.text}
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row-reverse",
                              flex: "auto",
                            }}
                          >
                            {todo.favourited ? (
                              <i
                                style={{ color: "#FF0055", opacity: 0.2 }}
                                className="fas fa-star"
                              ></i>
                            ) : null}
                            <span
                              className="noselect"
                              style={{
                                float: "right",
                                marginRight: "40px",
                                color: "#61C6FF",
                                opacity: 0.5,
                              }}
                            >
                              {todo.date}
                            </span>
                          </div>
                        </ListGroupItem>
                      );
                    })}
                </ListGroup>
              )}
            </div>
          )}
        </div>
      </div>
      <img
        alt="robot"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          maxHeight: 400,
          height: "40vh",
          zIndex: -1,
          opacity: 0.3,
        }}
        src={robot}
      />
    </div>
  );
};

export default App;
